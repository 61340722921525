<template>
  <v-app>
    <v-form class="client-form" ref="form" v-model="valid" lazy-validation>
      <v-divider class="divider" />
      <h3>Stripe customers:</h3>
      <div v-if="customersPreboarding.length">
        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="customerToPreboard"
              @change="setCustomerPreboarding"
              :items="customersPreboarding"
              return-object
              item-text="name"
              label="Preboarded customers from Stripe"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-switch
            v-model="isUsingExistingCustomer"
            label="Allocate to an existing client"
          />
          <v-col v-if="existingCustomersWithoutStripe.length" cols="12" sm="12">
            <v-autocomplete
              :disabled="!isUsingExistingCustomer"
              v-model="usingExistingCustomerObject"
              @change="setCustomerWithoutStripe"
              :items="existingCustomersWithoutStripe"
              return-object
              item-text="name"
              label="Existing customers without Stripe allocation"
              clearable
            >
              <template slot="item" slot-scope="data">
                {{ data.item.name }} - {{ data.item.domain }} -
                {{ data.item.activity_state }} - [{{
                  getSmmName(data.item.owner_id)
                }}]
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} - {{ data.item.domain }} -
                {{ data.item.activity_state }} - [{{
                  getSmmName(data.item.owner_id)
                }}]
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        No new customers on stripe
        <v-btn @click="fetchStripeData" :loading="isLoadingStripeData"
          >Refresh
        </v-btn>
      </div>

      <v-divider class="divider" />

      <div v-if="!isUsingExistingCustomer">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.businessName"
              placeholder="IG name shows on post/story widgets"
              label="Instagram name*"
              required
              :rules="[rules.required, rules.fieldMustBeWithoutSpaces]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.firstName"
              placeholder="Shows in sms message and export page"
              label="First Name*"
              required
              :rules="[rules.text, rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              class="prefix-text-field"
              v-model="form.primaryPhone"
              required
              type="number"
              :rules="[rules.required, rules.phoneLength]"
              prefix="+1"
              label="Add your phone number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.mail"
              placeholder="E-mail"
              label="E-mail*"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-select
              v-model="form.owner_id"
              :items="itemsSmm"
              item-text="name"
              item-value="id"
              label="Owner*"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6" v-show="false">
            <v-select
              multiple
              v-model="form.smmList"
              :items="itemsSmm"
              item-text="name"
              return-object
              label="SMM*"
              :rules="[rules.requiredMultiselect]"
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-select
              v-model="form.sendBy"
              return-object
              :items="sendByiItems"
              item-text="name"
              label="Send By*"
              :rules="[rules.requiredSelect]"
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-select
              v-model="form.activity_state"
              :items="activityStateList"
              label="Subscription Status*"
              :rules="[rules.requiredValue]"
              item-text="name"
              item-value="name"
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-chip
              v-if="planNickname"
              class="ma-2"
              color="cyan"
              label
              text-color="white"
            >
              <v-icon left> mdi-label </v-icon>
              {{ planNickname }}
            </v-chip>
            <v-select
              v-model="form.plan"
              return-object
              :items="planItems"
              :rules="[rules.requiredSelect]"
              item-text="name"
              label="Plan*"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="form.domain"
              :items="getDomainsName"
              label="Domain*"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              multiple
              v-model="form.subdomains"
              :items="optionalSubdomainsNames"
              label="Subdomains*"
              :rules="[rules.requiredMultiselect]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              multiple
              v-model="form.socialPlatforms"
              :items="socialPlatformsItems"
              item-text="name"
              item-value="name"
              label="Social Platforms*"
              :rules="[rules.requiredMultiselect]"
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-select
              v-model="form.time_zone"
              :items="timeZones"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              label="Time Zone*"
              validate-on-blur
              lazy-validation
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-select
              v-model="form.filter"
              :items="filters"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              label="Filter"
              validate-on-blur
              lazy-validation
            ></v-select>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-text-field
              v-model="form.post_per_week"
              placeholder="Posts Per Week"
              label="Posts Per Week"
              required
              :rules="[rules.numbers]"
            ></v-text-field>
          </v-col>
          <v-col data-app cols="12" sm="6">
            <v-text-field
              v-model="form.story_per_week"
              placeholder="Stories Per Week"
              label="Stories Per Week"
              required
              :rules="[rules.numbers]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.instagramPage"
              placeholder="IG page link"
              label="IG page link"
              :rules="[rules.fieldMustBeWithoutSpaces]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.facebookPage"
              placeholder="Facebook page"
              label="Facebook page"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.country"
              placeholder="Country"
              label="Country"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.state"
              placeholder="State"
              label="State"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.city"
              placeholder="City"
              label="City"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.street"
              placeholder="Street"
              label="Street"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.business_signature"
              placeholder="Business Signature"
              label="Business Signature"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              label="Picture"
              v-model="form.picUrl"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="form.rush_post"
              label="Rush Posts"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <NoteRichTextEditor
              :noteMessage="noteMessage"
              @updateNote="noteMessage = $event"
              :attachFiles="attachFiles"
              placeholder="Client Note"
              :isShowControlsBtn="false"
              @uploadFile="uploadFileHandler"
              @removeFile="removeFile($event)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(phone, index) in form.additionalPhones"
            cols="12"
            sm="6"
            :key="`phone${phone.id}`"
          >
            <v-text-field
              v-model="phone.value"
              placeholder="13049060514"
              label="Additional Phone"
              :rules="[rules.phone]"
              :append-icon="form.additionalPhones.length > 1 ? 'mdi-close' : ''"
              @click:append="form.additionalPhones.splice(index, 1)"
            ></v-text-field>
            <div
              class="add-more"
              v-if="form.additionalPhones.length === index + 1"
            >
              <button
                @click.prevent="addPhoneNumber(form.additionalPhones.length)"
                class="add-more__btn"
              >
                Add more
              </button>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-for="(email, index) in form.additional_emails"
            :key="`email${email.id}`"
          >
            <v-text-field
              v-model="email.value"
              placeholder="Email"
              label="Additional Email"
              :rules="[
                rules.email,
                rules.existingEmail(email.value, [
                  ...form.additional_emails.map((i) => i.value),
                  form.mail,
                ]),
              ]"
              :append-icon="
                form.additional_emails.length > 1 ? 'mdi-close' : ''
              "
              @click:append="form.additional_emails.splice(index, 1)"
            ></v-text-field>
            <div
              class="add-more"
              v-if="form.additional_emails.length === index + 1"
            >
              <button
                @click.prevent="addEmail(form.additional_emails.length)"
                class="add-more__btn"
              >
                Add more
              </button>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="submit-wrapper">
        <button
          v-if="usingExistingCustomerObject"
          @click.prevent="isDialogStripe = true"
          class="submit-btn"
        >
          Submit
        </button>
        <button v-else @click.prevent="validate" class="submit-btn">
          Submit
        </button>
      </div>
    </v-form>
    <v-dialog v-model="isDialogStripe" width="800">
      <v-card>
        <div class="form-summary">
          <div class="form-summary__title">
            Confirm allocation to Stripe account
          </div>
          <div class="form-summary__list">
            <div class="form-summary__item">
              <div class="name">Company Name:</div>
              <div class="data">
                {{
                  usingExistingCustomerObject &&
                  usingExistingCustomerObject.name
                }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Domain:</div>
              <div class="data">
                {{
                  usingExistingCustomerObject &&
                  usingExistingCustomerObject.domain
                }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Stripe Name:</div>
              <div class="data">
                {{ customerToPreboard && customerToPreboard.name }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Stripe Id:</div>
              <div class="data">
                {{ customerToPreboard && customerToPreboard.id }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Stripe Email:</div>
              <div class="data">
                {{ customerToPreboard && customerToPreboard.email }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Stripe Description:</div>
              <div class="data">
                {{ customerToPreboard && customerToPreboard.description }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">Stripe Plan nickname:</div>
              <div class="data">{{ planNickname }}</div>
            </div>
            <div class="form-summary__btn">
              <button
                @click.prevent="isDialogStripe = !isDialogStripe"
                class="cancel"
              >
                Cancel
              </button>
              <button @click.prevent="submitForm" class="submit">Submit</button>
            </div>
          </div>
          <div class="form-success" v-if="isSubmitStripeAllocate">
            <div class="form-success__message">
              Client allocated to Stripe account .<br />
            </div>
            <br />
            <div class="form-summary__btn">
              <button @click.prevent="reloadForm()" class="submit">
                Close
              </button>
            </div>
          </div>
        </div>
        <Loader v-if="isLoading" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <div class="form-summary">
          <div class="form-summary__title">Check The Details You Entered</div>
          <div class="form-summary__list">
            <div class="form-summary__item">
              <div class="name">Company Name:</div>
              <div class="data">{{ form.businessName }}</div>
            </div>
            <div class="form-summary__item">
              <div class="name">Domain:</div>
              <div class="data">{{ form.domain }}</div>
            </div>
            <div class="form-summary__item">
              <div class="name">Subdomains:</div>
              <div class="data">{{ form.subdomains.join(", ") }}</div>
            </div>
            <div class="form-summary__item">
              <div class="name">Social Platforms:</div>
              <div class="data">
                {{ form.socialPlatforms.join(", ") }}
              </div>
            </div>
            <div class="form-summary__item">
              <div class="name">First Name:</div>
              <div class="data">{{ form.firstName }}</div>
            </div>
            <div class="form-summary__item">
              <div class="name">Primary Phone:</div>
              <div class="data">{{ form.primaryPhone }}</div>
            </div>
            <div class="form-summary__item">
              <div class="name">Email:</div>
              <div class="data">{{ form.mail }}</div>
            </div>
            <div class="form-summary__item" v-if="form.owner_id">
              <div class="name">Owner:</div>
              <div class="data">
                {{ itemsSmm.filter((smm) => smm.id === form.owner_id)[0].name }}
              </div>
            </div>
            <div class="form-summary__item" v-if="form.smmList">
              <div class="name">SMM:</div>
              <div class="data">
                {{ smmNames }}
              </div>
            </div>
            <div class="form-summary__item" v-if="form.sendBy.name">
              <div class="name">Send By:</div>
              <div class="data">{{ form.sendBy.name }}</div>
            </div>
            <div class="form-summary__item" v-if="form.plan.name">
              <div class="name">Plan:</div>
              <div class="data">{{ form.plan.name }}</div>
            </div>
            <div class="form-summary__item" v-if="form.activity_state">
              <div class="name">Activity State:</div>
              <div class="data">{{ form.activity_state }}</div>
            </div>
            <div class="form-summary__item" v-if="noteMessage.trim() !== ''">
              <div class="name">Note:</div>
              <div
                class="data note-block"
                v-if="noteMessage.trim() !== ''"
                v-html="noteMessage"
              ></div>
            </div>
            <div class="form-summary__item" v-if="attachFiles.length">
              <div class="name">
                {{ attachFiles.length > 1 ? "Note files" : "Note file" }}:
              </div>
              <div class="data">
                {{ attachFiles.length }}
                {{ attachFiles.length > 1 ? "files" : "file" }}
              </div>
            </div>
          </div>
          <div class="form-summary__btn">
            <button @click.prevent="dialog = !dialog" class="cancel">
              Cancel
            </button>
            <button @click.prevent="submitForm" class="submit">Submit</button>
          </div>
        </div>
        <div class="form-success" v-if="isSubmit">
          <div class="form-success__message">
            Client created.<br />
            <a :href="metaAuthLink" target="_blank">
              Link for Meta authentication</a
            >
            <CopyButton :data="metaAuthLink" />
          </div>
          <br />
          <div class="form-summary__btn">
            <button @click.prevent="reloadForm()" class="submit">Close</button>
          </div>
        </div>
        <Loader v-if="isLoading" />
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import validationRules from "@/shared/validationRules";
import {
  SUPPORTED_SOCIAL_PLATFORMS,
  SUPPORTED_BRAND_STYLES,
  SUPPORTED_TIME_ZONES,
  SUPPORTED_FILTERS,
  SEND_BY_ITEMS,
  PLANS,
  ACTIVITY_STATE,
  EXCLUDED_OWNERS,
} from "@/constants/globals";
import NoteRichTextEditor from "@/components/global/NoteRichTextEditor";
import Loader from "@/components/global/Loader";
import { mapActions, mapGetters } from "vuex";
import CopyButton from "@/components/global/CopyButton";

export default {
  name: "NewClientForm",
  data: () => ({
    valid: true,
    dialog: false,
    isDialogStripe: false,
    form: {
      businessName: "",
      firstName: "",
      primaryPhone: "",
      mail: "",
      domain: null,
      subdomains: [],
      socialPlatforms: [],
      instagramPage: "",
      facebookPage: "",
      post_per_week: "",
      story_per_week: "",
      country: "USA",
      state: "",
      city: "",
      street: "",
      business_signature: "",
      smmList: [],
      owner_id: "",
      picUrl: null,
      sendBy: SEND_BY_ITEMS[0],
      plan: {},
      time_zone: "",
      filter: "normal",
      activity_state: "Trial",
      brand_font_families: [],
      brand_color_palettes: [],
      brand_logo: "",
      brand_headshots: [],
      brand_styles: [],
      stripe_customer_id: null,
      additionalPhones: [
        {
          id: 1,
          value: "",
        },
      ],
      rush_post: false,
      is_automated: false,
      additional_emails: [
        {
          id: 1,
          value: "",
        },
      ],
    },
    attachFiles: [],
    timeZones: SUPPORTED_TIME_ZONES,
    filters: SUPPORTED_FILTERS,
    socialPlatformsItems: SUPPORTED_SOCIAL_PLATFORMS,
    optionalSubdomainsNames: [],
    features: [],
    itemsSmm: [],
    sendByiItems: SEND_BY_ITEMS,
    planItems: PLANS,
    activityStateList: ACTIVITY_STATE,
    mediaFile: "",
    mediaFileUrl: "",
    isLoading: false,
    isSubmit: false,
    isSubmitStripeAllocate: false,
    customerId: null,
    smmList: [],
    noteMessage: "",
    customersPreboarding: [],
    customerToPreboard: null,
    planNickname: null,
    isUsingExistingCustomer: false,
    existingCustomersWithoutStripe: [],
    usingExistingCustomerObject: null,
    isLoadingStripeData: false,
  }),
  components: { Loader, CopyButton, NoteRichTextEditor },
  mixins: [validationRules],
  computed: {
    ...mapGetters("globals", ["getDomainsName"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    additionalPhonesList() {
      return this.form.additionalPhones.length > 0
        ? this.form.additionalPhones.map((i) => i.value).filter((i) => i.length)
        : [];
    },
    additionalEmailsList() {
      return this.form.additional_emails.length > 0
        ? this.form.additional_emails
            .map((i) => i.value)
            .filter((i) => i.length)
        : [];
    },
    metaAuthLink() {
      return `https://socialwonder.agency/fb-auth?id=${this.customerId}`;
    },
    smmNames() {
      return this.smmList
        .map((i) => this.itemsSmm.find((itemList) => itemList.id === i)?.name)
        .join(", ");
    },
  },
  async created() {
    this.isLoading = true;
    await this.getSmmList();
    this.form.smmList = this.itemsSmm.filter(
      (i) =>
        i.name === "tamar" ||
        i.name === "kayley" ||
        i.name === "dan" ||
        i.name === "ashton" ||
        i.name === "amanda"
    );
    this.form.owner_id = this.itemsSmm.find((i) => i.name === "dan")?.id;
    await this.fetchDomainsTree();

    await this.fetchStripeData();

    this.isLoading = false;
  },

  watch: {
    "form.domain": function (val) {
      if (val) {
        this.form.subdomains = [];
        let optionalSubdomains = this.getSubdomainsTree()(val);
        this.optionalSubdomainsNames = optionalSubdomains
          ? optionalSubdomains.map((sd) => sd.name)
          : [];
      }
    },
  },
  methods: {
    ...mapActions("clientForm", [
      "fetchSmmList",
      "sendClientForm",
      "getUrlForSendImg",
      "uploadImage",
    ]),
    ...mapActions("filesController", ["getUrlForSendFile", "uploadFile"]),
    ...mapActions("globals", ["fetchDomainsTree"]),
    ...mapGetters("globals", ["getSubdomainsTree"]),
    ...mapActions("clientSelection", ["sendNote", "fetchCustomerInfo"]),
    ...mapActions("customerPreboarding", [
      "fetchCustomersPreboarding",
      "fetchCustomersWithoutStripe",
      "allocateExistingCustomer",
    ]),
    ...mapActions("customerSubscription", ["getCustomerSubscription"]),

    async fetchStripeData() {
      try {
        this.isLoadingStripeData = true;
        this.customersPreboarding = await this.fetchCustomersPreboarding();
        this.existingCustomersWithoutStripe =
          await this.fetchCustomersWithoutStripe();
      } catch (error) {
        console.error(console.error());
      } finally {
        this.isLoadingStripeData = false;
      }
    },

    getSmmName(smm_id) {
      const smmItem = this.itemsSmm.find((i) => i.id === smm_id);
      return smmItem ? smmItem.name : "";
    },

    addPhoneNumber(lastIndex) {
      this.form.additionalPhones.push({
        id: this.form.additionalPhones[lastIndex - 1].id + 1,
        value: "",
      });
    },
    addEmail(lastIndex) {
      this.form.additional_emails.push({
        id: this.form.additional_emails[lastIndex - 1].id + 1,
        value: "",
      });
    },

    validate() {
      this.smmList = this.form.smmList.map((smm) => smm.id);

      !this.smmList.includes(this.form.owner_id)
        ? this.smmList.push(this.form.owner_id)
        : null;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.dialog = true;
      }
    },

    async submitForm() {
      try {
        this.isLoading = true;

        if (this.usingExistingCustomerObject) {
          const stripeCustomerId = this.customerToPreboard.id;
          const customerId = this.usingExistingCustomerObject.id;
          await this.allocateExistingCustomer({ stripeCustomerId, customerId });

          this.isSubmitStripeAllocate = true;
          return;
        }

        if (this.form.picUrl) {
          await this.attachFile(this.form.picUrl);
        }

        const params = {
          name: this.form.businessName,
          personal_name: this.form.firstName,
          primary_phone: "1" + this.form.primaryPhone,
          owner_id: this.form.owner_id,
          mail: this.form.mail,
          ig_page: this.form.instagramPage,
          social_platforms: this.form.socialPlatforms,
          fb_page: this.form.facebookPage,
          address_country: this.form.country,
          address_state: this.form.state,
          address_city: this.form.city,
          address_street: this.form.street,
          business_signature: this.form.business_signature,
          smm_id: this.smmList,
          send_by: this.form.sendBy.name,
          plan: this.form.plan.id,
          image_url: this.mediaFile,
          additional_phones: this.additionalPhonesList,
          post_per_week: this.form.post_per_week,
          story_per_week: this.form.story_per_week,
          domain: this.form.domain,
          subdomains: this.form.subdomains,
          time_zone: this.form.time_zone,
          filter: this.form.filter,
          activity_state: this.form.activity_state,
          brand_font_families: this.form.brand_font_families,
          brand_color_palettes: this.form.brand_color_palettes,
          brand_logo: this.form.brand_logo,
          brand_headshots: this.form.brand_headshots,
          brand_styles: this.form.brand_styles,
          stripe_customer_id: this.form.stripe_customer_id,
          rush_post: this.form.rush_post,
          is_automated: this.form.domain === "ecommerce",
          additional_emails: this.additionalEmailsList,
        };
        const data = await this.sendClientForm(params);
        if (data) {
          this.customerId = data["id"];
          await this.saveNote(this.customerId);
          this.isSubmit = true;
        }
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },

    async setCustomerWithoutStripe() {
      console.log(
        "usingExistingCustomerObject",
        this.usingExistingCustomerObject
      );
    },
    async setCustomerPreboarding() {
      console.log("customerToPreboard:", this.customerToPreboard);
      this.form.stripe_customer_id = this.customerToPreboard.id;
      this.form.firstName = this.customerToPreboard.name;
      this.form.mail = this.customerToPreboard.email;
      if (this.customerToPreboard.phone)
        this.form.primaryPhone = this.customerToPreboard.phone;
      if (this.customerToPreboard.description)
        this.noteMessage = this.customerToPreboard.description;

      const subscriptionObjects = await this.getCustomerSubscription(
        this.form.stripe_customer_id
      );
      console.log("subscriptionObjects", subscriptionObjects);
      if (
        subscriptionObjects &&
        subscriptionObjects.length &&
        subscriptionObjects[0].plan &&
        subscriptionObjects[0].plan.nickname
      ) {
        this.planNickname = subscriptionObjects[0].plan.nickname;
      }
    },
    uploadFileHandler(files) {
      this.attachFiles = [...this.attachFiles, ...files];
    },
    removeFile(file) {
      this.attachFiles = this.attachFiles.filter((i) => i.name !== file.name);
    },
    async saveNote(newCustomerId = null) {
      let params = {
        note: true,
        comments: this.noteMessage,
        smmId: this.getCurrentUser && this.getCurrentUser.id,
        attached_files: [],
      };
      if (this.attachFiles.length) {
        for (const file of this.attachFiles) {
          params.attached_files.push(
            await this.uploadFiles(file, newCustomerId)
          );
        }
      }
      await this.sendNote({
        customerId: newCustomerId,
        params: params,
      });

      params = {};
      this.noteMessage = "";
      this.attachFiles = [];
    },
    async uploadFiles(file, newCustomerId) {
      const { url, mediaId, fields } = await this.getUrlForSendFile({
        file,
        customerId: newCustomerId,
        type: "notes",
        isTag: false,
        imageId: null,
      });
      await this.uploadFile({ url, file: file.file, fields });
      return `${url}${fields.key}`;
    },
    async getSmmList() {
      try {
        const data = await this.fetchSmmList();
        if (data) {
          let mainOwnersList = data.filter(
            (owner) => !EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          let subOwnersList = data.filter((owner) =>
            EXCLUDED_OWNERS.find((item) => item === owner.name)
          );
          this.itemsSmm = [
            ...mainOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
            ...subOwnersList.sort((a, b) => (a.name > b.name ? 1 : -1)),
          ];
        }
      } catch (e) {
        throw e;
      }
    },
    async attachFile(file) {
      const { url, mediaId, fields } = await this.getUrlForSendFile({
        file,
        customerId: null,
        type: "customer_image",
      });
      await this.uploadFile({ url, file, fields });
      this.mediaFile = fields.key.split("/").pop();
    },

    reloadForm() {
      this.$router.go();
    },

    resetForm() {
      this.dialog = false;
      this.isDialogStripe = false;
      this.isSubmit = false;
      this.isSubmitStripeAllocate = false;
      this.form = {
        businessName: "",
        firstName: "",
        primaryPhone: "",
        mail: "",
        instagramPage: "",
        facebookPage: "",
        country: "",
        state: "",
        subdomains: [],
        domain: "",
        time_zone: "",
        filter: "normal",
        socialPlatforms: [],
        city: "",
        street: "",
        business_signature: "",
        smmList: [],
        picUrl: null,
        sendBy: {},
        plan: {},
        additionalPhones: [
          {
            id: 1,
            value: "",
          },
        ],
        activity_state: "Trial",
        brand_font_families: [],
        brand_color_palettes: [],
        brand_logo: "",
        brand_headshots: [],
        brand_styles: [],
        stripe_customer_id: null,
      };
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.divider {
  border-width: thin;
  border-color: #7e5689;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.add-more {
  font-size: 14px;
  color: #7e5689;
  font-weight: 500;
  margin-top: 0;
}
.submit-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .submit-btn {
    background: #7e5689;
    border-radius: 26px;
    height: 40px;
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #fff;
    padding: 0 20px;
  }
}
.client-form {
  ::v-deep {
    .v-messages__message {
      color: red;
    }
    .col-sm-6,
    .col-12 {
      padding-bottom: 0;
    }
  }
}
.form-summary {
  width: 100%;
  padding: 35px 35px;
  position: relative;
  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1f2325;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    .name {
      width: 200px;
      margin-right: 10px;
      flex-shrink: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #1f2325;
    }
    .data {
      font-size: 14px;
      line-height: 140%;
      color: #1f2325;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
      margin: 0 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      height: 37px;
      min-width: 120px;
      border-radius: 16px;
      box-sizing: border-box;
      border: 1px solid transparent;
      transition: all 0.3s ease;
      &.cancel {
        border: 1px solid #7e5689;
        color: #1f2325;
        &:hover {
          background: rgba(126, 86, 137, 0.1);
        }
      }
      &.submit {
        background: #7e5689;
        color: #fff;
        &:hover {
          background: #61346e;
        }
      }
    }
  }
  .note-block {
    width: 100%;
    max-width: 300px;
  }
}
.form-success {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #fff;
  &__message {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #1f2325;
  }
}
.error-email {
  font-size: 12px;
  color: red;
}
</style>
