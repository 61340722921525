<template>
  <div class="new-client">
    <div class="new-client__wrapper">
      <div class="new-client__logo">
        <img src="@/assets/img/logo_full.svg" alt="" />
      </div>
      <div class="new-client__title">Client form</div>
      <NewClientForm />
    </div>
  </div>
</template>

<script>
import NewClientForm from "@/components/NewClient/NewClientForm.vue";
export default {
  name: "Index",
  components: { NewClientForm },
};
</script>

<style scoped lang="scss">
.new-client {
  width: 100%;
  padding: 100px 15px;
  &__wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  &__logo {
    width: 100%;
    max-width: 290px;
    margin: 0 auto 30px auto;
    img {
      width: 100%;
    }
  }
  &__title {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    color: #1f2325;
  }
}
</style>
